<template>
  <div class="validCodeInput" style="text-align: left;">
    <van-nav-bar
      title="彩速达"
      left-text="返回"
      left-arrow
      @click-left="onReturn"
    />
    <br /><br /><br />
    <div class="validCodePageWrapper" style="padding: 0 35px;">
      <div class="logo"><img :src="logoPngPath" alt="彩速达" /></div>
      <div class="welcome">
        <span style="font-size: 25px;">你好，</span><br />
        <span style="font-size: 25px;">欢迎加入彩速达！</span>
      </div>
      <div class="clear"></div>
      <br /><br /><br />
      <van-field v-model="tel" type="tel" label="账号" placeholder="请输入本人手机号" @input="inputChange" />
      <van-field v-model="password" type="password" label="密码" placeholder="请输入密码" maxlength='18' />
      <van-field v-model="confirmPassword" type="password" label="确认密码" placeholder="请确认密码" maxlength='18' />

      
    <van-field v-model="code" center label="验证码" placeholder="请输入验证码">
        <template #button>
        <van-button size="small" type="primary" @click="sendCode" :disabled="btnDisabled">发送验证码</van-button>
        </template>
    </van-field>

      <van-button type="info" style="margin-top:60px;" :disabled="btnDisabled" block @click="onSubmit">注册</van-button>

      <!-- <van-password-input
        :value="value"
        :gutter="15"
        length="6"
        info="5分钟内输入均有效，请及时输入验证."
        :error-info="errorInfo"
        :mask="false"
        :focused="showKeyboard"
        @focus="showKeyboard = true"
      />

      
      <van-number-keyboard
          v-model="value"
          theme="custom"
          close-button-text="确定"
          @close="onComplete"
          :show="showKeyboard"
          @blur="showKeyboard = false"
      /> -->
    </div>

  </div>
</template>

<script>
import { doLogin,sendCode,register } from "@/api/login";
import {Notify} from "vant";
import logoPng from "@/assets/logo.png";
import MD5 from "md5.js";

export default {
  name: "ValidCodeInput",
  components: {},
  data() {
    return {
      value: '',
      isSubmited: false,
      errorInfo: '',
      showKeyboard: true,
      logoPngPath: logoPng,
      tel: "",
      password: "",
      confirmPassword: "",
      code: "",
      btnDisabled:true
    };
  },
  watch: {
    value(value) {
      if (value.length === 6) {
        // 检查验证码是否正确
        this.onComplete();
      } else if(value.length > 6) {
        this.value = value.substring(0, 6);
      } else {
        this.errorInfo = '';
      }
    },
  },
  methods: {
    inputChange(val) {
      // console.log(val)
      if(val) {
        if(/^1(3|4|5|6|7|8|9)\d{9}$/.test(val)) {
          this.btnDisabled = false;
        } else {
          this.btnDisabled = true;
        }
      } else {
        this.btnDisabled = true;
      }
    },
    onReturn() {
      this.$router.go(-1);
    },
    onComplete() {
      if (this.value.length === 6) {
        if(!this.isSubmited) {
          // 要防止二次提交
          this.isSubmited = true;
          // 请求服务端验证验证码
          let params = { phone: this.$route.query.phone, code: this.value };
          doLogin(params).then(response => {
            const code = response['code'];
            const retData = response.data;
            console.log(retData);
            if(code == 0) {
              // 验证成功
              // 根据角色类型进行处理
              const role = retData['role'];
              // 设置登录token
              localStorage.setItem("token", retData['token']);
              // 同时设置保存角色
              localStorage.setItem("role", role);
              // 区域经理
              if(role == 'region_manager') {
                // 直接进入首页（区域经理）
                this.$router.push({path: '/'});
              } else if(role == 'terminator') {
                // 终端用户
                // 用户状态
                const status = retData['status'];
                // 临时账号 或者 待审核中的账号 去到 指定区域经理页面
                // 2.1 首次注册或者临时账号状态 进入 指定区域经理页面
                if(status == -9) {
                  let queryParams = { phone: params.phone, status: status };
                  this.$router.push({path: '/setRegionManager',query: queryParams});
                } else if(status == -1) {
                  let queryParams = { phone: params.phone, status: status };
                  this.$router.push({path: '/checkPending',query: queryParams});
                } else if(status == 1) {
                  // 直接进入首页（终端）
                  this.$router.push({path: '/'});
                }
              }
            } else {
              // this.errorInfo = '验证码输入错误';
              this.errorInfo = response['msg'];
            }

            // 报错
            this.isSubmited = false;
          }).catch(err => {
            console.log(err);
            this.isSubmited = false;
          });
        }
      } else {
        this.errorInfo = '';
        this.showKeyboard = true;
      }
    },
    sendCode() {
        if(!this.tel){
            Notify("请先输入账号");
            return false;
        }
        if(!/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.tel)){
            Notify("请正确输入账号");
            return false;
        }
        let params = { phone: this.tel}
        sendCode(params).then(res => {
            if(res.code == 0){
                Notify(res.data);
            }
            
        })
    },
    onSubmit(){
        if(!this.tel){
            Notify("请先输入账号");
            return false;
        }
        if(!/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.tel)){
            Notify("请正确输入账号");
            return false;
        }
        if(!this.password){
            Notify("请输入密码");
            return false;
        }
        if(!this.confirmPassword){
            Notify("请输入确认密码");
            return false;
        }
        if(this.password.length < 6 || this.password.length > 18 || this.confirmPassword.length < 6 || this.confirmPassword.length > 18){
            Notify("请输入6到18位的密码");
            return false;
        }
        if(!this.code){
            Notify("请输入验证码");
            return false;
        }
        if(this.confirmPassword != this.password){
            Notify("两次密码输入不一致");
            return false;
        }
        let params = {
            phone:this.tel,
            password: new MD5().update(this.password).digest('hex'),
            confirmPassword: new MD5().update(this.confirmPassword).digest('hex'),
            code: this.code
        }
        // 要防止二次提交
        this.isSubmited = true;
        register(params).then(response => {
            console.log(response);
            const code = response['code'];
            const retData = response.data;
            console.log(retData);
            if(code == 0) {
              // 验证成功
              // 根据角色类型进行处理
              const role = retData['role'];
              // 设置登录token
              localStorage.setItem("token", retData['token']);
              // 同时设置保存角色
              localStorage.setItem("role", role);
              // 区域经理
              if(role == 'region_manager') {
                // 直接进入首页（区域经理）
                this.$router.push({path: '/'});
              } else if(role == 'terminator') {
                // 终端用户
                // 用户状态
                const status = retData['status'];
                // 临时账号 或者 待审核中的账号 去到 指定区域经理页面
                // 2.1 首次注册或者临时账号状态 进入 指定区域经理页面
                if(status == -9) {
                  let queryParams = { phone: params.phone, status: status };
                  this.$router.push({path: '/setRegionManager',query: queryParams});
                } else if(status == -1) {
                  let queryParams = { phone: params.phone, status: status };
                  this.$router.push({path: '/checkPending',query: queryParams});
                } else if(status == 1) {
                  // 直接进入首页（终端）
                  this.$router.push({path: '/'});
                }
              }
            }
          this.isSubmited = false;
        }).catch(err => {
          console.log(err);
          this.isSubmited = false;
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.logo {
  width: 90px;
  height: 90px;
  float: left;
  margin-right: 15px;
}
.logo img {
  width: 100%;
  height: 100%;
}
.welcome {
  width:calc(100% - 116px);
  height: 60px;
  margin: 15px 0;
  float: left;
  border-left: 1px solid #e7e4e4;
  padding-left: 10px;
}
.clear {
  clear: both;
}
</style>
